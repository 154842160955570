<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Tạo mới</h6>
        </template>
        <b-row>
          <b-col sm="6">
            <b-form-group label="Tên template (*)">
              <b-form-input
                  v-model="input.name"
                  placeholder="Tên template"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Tiêu đề">
              <b-form-input
                  v-model="input.title"
                  placeholder="Tiêu đề"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Mô tả">
              <b-form-textarea
                  id="input-sdesc"
                  v-model="input.description"
                  required
                  placeholder="Nhập mô tả"
                  rows="3"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group label="Banner (*):">
              <b-form-file
                  @change="onImageChange($event,'image')"
                  placeholder="chọn ảnh"
                  accept="image/*"
                  type="file"
              ></b-form-file>
              <div v-if="input.image">
                <img
                    style="width: 100px;"
                    :src="input.image"
                    :required="input.image ? false : true"
                    alt=""
                />
              </div>
            </b-form-group>
            <b-form-group label="Loại (*)">
              <b-form-select
                  v-model="input.type"
                  :options="options.types"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Trạng thái (*)">
              <b-form-select
                  v-model="input.status"
                  :options="options.status"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Tên button (*)">
              <b-form-input
                  v-model="input_button.name"
                  placeholder="Tên button"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Ảnh (*):">
              <b-form-file
                  @change="onImageChange($event,'button')"
                  placeholder="chọn ảnh"
                  accept="image/*"
                  type="file"
              ></b-form-file>
              <div v-if="input_button.image">
                <img
                    style="width: 100px;"
                    :src="input_button.image"
                    :required="input_button.image ? false : true"
                    alt=""
                />
              </div>
            </b-form-group>
            <b-form-group label="Loại">
              <b-form-select
                  v-model="input_button.type"
                  :options="options.button_types"
              ></b-form-select>
            </b-form-group>
            <b-form-group label="Direction" v-if="input_button.type == 'other'">
              <b-form-input
                  v-model="input_button.direction"
                  placeholder="Direction"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="sort">
              <b-form-input
                  type="number"
                  v-model="input_button.sort"
                  placeholder="Sort"
              ></b-form-input>
              <input type="hidden" v-model="input_button.old_sort"/>
            </b-form-group>
            <b-col class="text-center">
              <b-button class="mr-2" variant="outline-primary" @click="store_button()">Cập nhật</b-button>
            </b-col>
            <b-table
                :items="input.button"
                :fields="fields"
                ref="table"
                striped
                hover
                responsive
                caption-top
            >

              <!--              <template #cell(type)="data">-->
              <!--                <b-badge-->
              <!--                    v-for="type in options.button_types"-->
              <!--                    v-if="data.item.type == type.value"-->
              <!--                    variant="success"-->
              <!--                >-->
              <!--                </b-badge>-->
              <!--              </template>-->

              <template #cell(image)="data">
                <img
                    style="width: 50px;"
                    :src="data.item.image"
                    :required="data.item.image ? false : true"
                    alt=""
                />
              </template>

              <template #cell(action)="data">
                <b-button style="margin-right: 10px;" @click="edit_button(data.item.sort)" pill variant="info"
                          size="sm">Sửa
                </b-button>
                <b-button @click="delete_button(data.item.sort)"
                          pill
                          variant="danger"
                          size="sm"
                >Xóa
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
          <b-row>
              <b-col sm="6">
                  <b-form-group label="Nội dung dạng danh sách ( Cách nhau bằng dấu '|' )">
                      <b-form-textarea
                          id="input-sdesc"
                          v-model="input.step_message"
                          required
                          placeholder="Nhập nội dung"
                          rows="3"
                      ></b-form-textarea>
                  </b-form-group>
              </b-col>
          </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button class="mr-2" v-if="this.id == '' || this.id == null" variant="outline-primary" @click="store()"
            >Thêm mới
            </b-button>
            <b-button class="mr-2" v-if="this.id != '' && this.id != null" variant="outline-primary" @click="update()"
            >Lưu
            </b-button>
            <b-button class="mr-2" variant="outline-danger" @click="cancel()"
            >Hủy
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import "vue2-datepicker/index.css";
import CmsRepository from "@/core/repositories/cmsRepository";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const WalletRunRepository = RepositoryFactory.get("WalletRun");

Vue.use(Notifications);
export default {
  mixins: [Common],
  data() {
    return {
      input: {
        name: null,
        title: null,
        description: null,
        image: null,
        type: 'manual',
        sort: 0,
        step_message: '',
        button: [],
        status: 1,
      },
      input_button: {
        name: null,
        image: null,
        type: null,
        direction: null,
        sort: 1,
        old_sort: -1
      },
      id: this.$route.query.id ?? null,
      options: {
        status: [
          {value: 1, text: "Hiển thị"},
          {value: 2, text: "Bảo trì"},
          {value: 0, text: "Ẩn"},
          {value: -1, text: "Đã xóa"},
        ],
        button_types: [
          {value: "is_close", text: "Nút đóng"},
          {value: "is_detail", text: "Nút Chi tiết"},
          // {value: "other", text: "Direction"},
        ],
        types: [
          {value: "manual", text: "Push tay"},
          {value: "auto_expired", text: "Push tự động khi hết hạn"},
          {value: "bonus_prize", text: "Push khi cộng ví thưởng"},
          {value: "change_password", text: "Push cảnh báo đổi mật khẩu"},
          {value: "balance_lock", text: "Push cảnh báo khóa số dư"},
        ]
      },
      fields: [
        {
          key: "sort",
          label: "Vị trí",
        },
        {
          key: "name",
          label: "Tên",
        },
        {
          key: "image",
          label: "Ảnh",
        },
        {
          key: "type",
          label: "Loại",
        },
        {
          key: "action",
          label: "Hành động",

        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Config template message", route: '/tools/message-template'},
    ]);
    if (this.$route.query.id != '' && this.$route.query.id != null) {
      this.getDetail(this.$route.query.id)
    }
  },
  methods: {
    edit_button(sort) {
      this.input_button = {...this.input.button[(sort - 1)]}
      this.input_button.old_sort = this.input_button.sort;
    },
    store_button() {
      let data = {...this.input_button};
      data.old_sort = parseInt(data.old_sort);
      data.sort = parseInt(data.sort);
      let index = this.input.button.findIndex(obj => obj.sort === data.sort);
      let oldData = this.input.button[index];
      if (data.old_sort > -1) {
        let indexOld = this.input.button.findIndex(obj => obj.sort === data.old_sort);
        this.input.button.splice(indexOld, 1);
      }
      let newData = [];
      let newSort = 1;
      this.input.button.forEach(function (item) {
        if (data.old_sort != data.sort && data.old_sort === newSort) {
          if (index > -1) {
            newData.push({
              sort: newSort,
              name: oldData.name,
              image: oldData.image,
              type: oldData.type,
              direction: oldData.direction,
            });
            newSort++;
          } else {
            newData.push({
              sort: newSort,
              name: item.name,
              image: item.image,
              type: item.type,
              direction: item.direction,
            });
            newSort++;
          }
          return;
        }
        if (data.sort === newSort) {
          newData.push({
            sort: newSort,
            name: data.name,
            image: data.image,
            type: data.type,
            direction: data.direction,
          });
          newSort++;
        }
        if (data.old_sort !== newSort) {
          newData.push({
            sort: newSort,
            name: item.name,
            image: item.image,
            type: item.type,
            direction: item.direction,
          });
          newSort++;
        }
      })
      if (data.sort > newData.length) {
        newData.push({
          sort: newSort,
          name: data.name,
          image: data.image,
          type: data.type,
          direction: data.direction,
        });
      }
      this.input.button = newData;

      this.input_button = {
        name: null,
        image: null,
        type: null,
        direction: null,
        sort: (newData.length + 1),
        old_sort: -1
      };
    },
    delete_button(sort) {
      let index = this.input.button.findIndex(obj => obj.sort === sort);
      this.input.button.splice(index, 1);
    },
    cancel() {
      return this.$router.push({name: "messageTemplate"});
    },
    validateStoreForm() {
      if (this.input.name === "" || this.input.name === null) {
        this.notifyAlert("warn", "Tên không được để trống");
        return false;
      }
      if (this.input.title === "" || this.input.title === null) {
        this.notifyAlert("warn", "Tiêu đề không được để trống");
        return false;
      }
      if (this.input.description === "" || this.input.description === null) {
        this.notifyAlert("warn", "Mô tả không được để trống");
        return false;
      }
      if (this.input.image === "" || this.input.image === null) {
        this.notifyAlert("warn", "Ảnh không được để trống");
        return false;
      }
      return true;
    },
    store() {
      if (!this.validateStoreForm()) {
        return;
      }

      let inputParam = this.input;
      inputParam.button = JSON.stringify(inputParam.button);
      this.$bus.$emit("show-loading", true);
      CmsRepository.storeTemplate(inputParam)
          .then((response) => {
            if (response) {
              this.notifyAlert("success", response.data.message);
              this.$bus.$emit("show-loading", false);
              return this.$router.push({name: "messageTemplate"});
            } else {
              this.notifyAlert("warn", response.data.message);
              this.$bus.$emit("show-loading", false);
              return false;
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },
    update() {
      if (!this.validateStoreForm()) {
        return;
      }
      let inputParam = this.input;
      inputParam.button = JSON.stringify(inputParam.button);
      this.$bus.$emit("show-loading", true);
      inputParam.id = this.id
      CmsRepository.updateTemplate(this.id, inputParam)
          .then((response) => {
            if (response.data.error_code == 1) {
              // this.notifyAlert("success", response.data.message);
              this.$bus.$emit("show-loading", false);
              return this.$router.push({name: "messageTemplate"});
            } else {
              this.notifyAlert("success", response.data.message);
              this.$bus.$emit("show-loading", false);
              return this.$router.push({name: "messageTemplate"});
            }
          })
          .catch(() => {
            alert("Có lỗi xảy ra");
          });
    },
    async getDetail(id) {
      this.$bus.$emit("show-loading", true);
      await CmsRepository.detailTemplate(id)
          .then((response) => {
            if (response.data.error_code !== 0) {
              this.notifyAlert("warn", response.data.message);
              this.$bus.$emit("show-loading", false);
              return false;
            }

            let result = response.data.data;

            this.input.name = result.name;
            this.input.title = result.title;
            this.input.description = result.description;
            this.input.image = result.image;
            this.input.type = result.type;
            this.input.button = result.button;
            this.input.status = result.status;
            this.$bus.$emit("show-loading", false);
          })
          .catch((error) => {
            alert(error);
            this.$bus.$emit("show-loading", false);
          });
    },
    onImageChange(e, type, position) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 2) {
        this.$notify({
          group: "foo",
          type: "error",
          title: "Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia(type, position);
      //this.createImage(files[0]);
    },
    uploadMedia: async function (type) {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);

      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
              if (type == 'button') {
                this.input_button.image = response.data.data.link;
              } else {
                this.input.image = response.data.data.link;
              }
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
  },

  async created() {
  },
};
</script>

